<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
        <div class="card">
          <div class="card-header bg-white">
            <h5 class="card-title font-weight-semibold">Total Aktifitas Task ID Harian</h5>
          </div>

          <ul class="nav nav-tabs nav-justified nav-tabs-highlight mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTab('RSH')" data-toggle="tab" 
            :class="activeTab == 'RSH' ? 'nav-link active' : 'nav-link'">RSH</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab('BPJS')" data-toggle="tab" 
            :class="activeTab == 'BPJS' ? 'nav-link active' : 'nav-link'">BPJS</a></li>
          </ul>

          <div class="tab-content">
            
            <div :class="activeTab == 'RSH' ? 'tab-pane fade show active' : 'tab-pane fade'" id="actTaskRsh">
              <div class="card-header">
  							<div class="col-md-8">
                  <div class="form-group mb-0 d-flex">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
                      :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
                      :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                  <thead>
                    <tr>
                      <th rowspan="2">Tanggal</th>
                      <th rowspan="2">Type</th>
                      <th colspan="7" class="text-center">Task ID</th>
                      <th rowspan="2">Aksi</th>
                    </tr>
                    <tr class="text-center">
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!--
                    <tr v-for="(v,k) in (dataRSH.data||[])" :key="k">
                      <td>{{v.lrt_date}}</td>
                      <td>{{v.lrt_task_1}}</td>
                      <td>{{v.lrt_task_2}}</td>
                      <td>{{v.lrt_task_3}}</td>
                      <td>{{v.lrt_task_4}}</td>
                      <td>{{v.lrt_task_5}}</td>
                      <td>{{v.lrt_task_6}}</td>
                      <td>{{v.lrt_task_7}}</td>
                      <td>
                        <button @click="doSync(v.lrt_date)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                        class="btn btn-light" title="Sinkronkan Data" v-b-tooltip.hover><i class="icon-spinner11"></i></button>
                      </td>
                    </tr>
                    -->

                    <div class="display-c" v-for="(v,k) in (dataRSH.data||[])" :key="k">
                      <tr>
                        <td rowspan="4">{{v.lrt_date}}</td>
                      </tr>
                      <tr>
                        <td>BPJS</td>
                        <td>{{v.lrt_task_1_jkn}}</td>
                        <td>{{v.lrt_task_2_jkn}}</td>
                        <td>{{v.lrt_task_3_jkn}}</td>
                        <td>{{v.lrt_task_4_jkn}}</td>
                        <td>{{v.lrt_task_5_jkn}}</td>
                        <td>{{v.lrt_task_6_jkn}}</td>
                        <td>{{v.lrt_task_7_jkn}}</td>
                        
                        <td rowspan="4"> <button @click="doSync(v.lrt_date)" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                        class="btn btn-light" title="Sinkronkan Data" v-b-tooltip.hover><i class="icon-spinner11"></i></button></td>
                      </tr>
                      <tr>
                        <td>Non BPJS</td>
                        <td>{{v.lrt_task_1_non_jkn}}</td>
                        <td>{{v.lrt_task_2_non_jkn}}</td>
                        <td>{{v.lrt_task_3_non_jkn}}</td>
                        <td>{{v.lrt_task_4_non_jkn}}</td>
                        <td>{{v.lrt_task_5_non_jkn}}</td>
                        <td>{{v.lrt_task_6_non_jkn}}</td>
                        <td>{{v.lrt_task_7_non_jkn}}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>{{v.lrt_task_1}}</td>
                        <td>{{v.lrt_task_2}}</td>
                        <td>{{v.lrt_task_3}}</td>
                        <td>{{v.lrt_task_4}}</td>
                        <td>{{v.lrt_task_5}}</td>
                        <td>{{v.lrt_task_6}}</td>
                        <td>{{v.lrt_task_7}}</td>
                      </tr>
                    </div>



                    <tr v-if="!(dataRSH.data||[]).length">
                      <td colspan="9" class="text-center">Tidak Ada Data</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="table-info">
                      <th colspan="2"><span class="font-weight-semibold">Total</span></th>
                      <td>{{totalRSH.task_1}}</td>
                      <td>{{totalRSH.task_2}}</td>
                      <td>{{totalRSH.task_3}}</td>
                      <td>{{totalRSH.task_4}}</td>
                      <td>{{totalRSH.task_5}}</td>
                      <td>{{totalRSH.task_6}}</td>
                      <td>{{totalRSH.task_7}}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <b-card-footer class="pagination-custom" v-if="(dataRSH.data||[]).length&&data.total>data.per_page">        
              <b-pagination
                  class="mb-0"
                  v-model="pageNo"
                  :per-page="data.per_page"
                  :total-rows="data.total"
              />
              </b-card-footer>
            </div>

            <div :class="activeTab == 'BPJS' ? 'tab-pane fade show active' : 'tab-pane fade'" class="tab-pane fade" id="actTaskBpjs">
              <div class="card-header">
                <div class="row">
                  
                  <div class="col-md-3 mb-2">
                      <v-select placeholder="Pilih Type" @input="changeTypeDashboard($event)" v-model="filter.typeFilter"
                          :options="Config.mr.typeDashboard" label="text" :clearable="true"
                          :reduce="v=>v.value">
                      </v-select>
                  </div>

                  <template v-if="filter.typeFilter == 'DB'">
                    <div class="col-md-2">
                      <v-select placeholder="Pilih Jenis Bulan" v-model="filter.bulan"
                          :options="Config.mr.monthName" label="text" :clearable="true"
                          :reduce="v=>v.value">
                      </v-select>
                    </div>
                    
                    <div class="col-md-2"> 
                      <v-select placeholder="Pilih Tahun" v-model="filter.tahun"
                          :options="mTahun" label="text" :clearable="true"
                          :reduce="v=>v.value">
                      </v-select>
                    </div>

                    <div class="col-md-2">
                      <v-select @input="doFill()" placeholder="Pilih Waktu" v-model="filter.type"
                          :options="Config.mr.rsTime" label="text" :clearable="true"
                          :reduce="v=>v.value">
                      </v-select>
                    </div>
                  </template>

                  <template v-if="filter.typeFilter == 'DT'">
                    <div class="col-md-3">
                        <div class="input-group">
                            <div class="input-group mb-3">
                                <datepicker
                                input-class="form-control transparent"
                                placeholder="Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar"
                                v-model="filter.date">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-3">
                      <v-select @input="doFill()" placeholder="Pilih Waktu" v-model="filter.type"
                          :options="Config.mr.rsTime" label="text" :clearable="true"
                          :reduce="v=>v.value">
                      </v-select>
                    </div>
                  </template>

                  <div class="col-auto" v-if="filter.typeFilter">
                      <button @click="doSearch()" class="btn btn-labeled btn-labeled-left btn-primary">
                          <b><i class="icon-search4"></i></b>
                          <span>Cari</span>
                      </button>
                      <button @click="doReset" class="btn btn-outline-warning ml-1">Reset</button>
                  </div>

                  
                </div>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                  <thead>
                    <tr>
                      <th rowspan="2">Tanggal</th>
                      <th rowspan="2">Poli</th>
                      <th colspan="2">Task ID 1</th>
                      <th colspan="2">Task ID 2</th>
                      <th colspan="2">Task ID 3</th>
                      <th colspan="2">Task ID 4</th>
                      <th colspan="2">Task ID 5</th>
                      <th colspan="2">Task ID 6</th>
                      <th rowspan="2">Jumlah Antrean</th>
                    </tr>
                    <tr class="text-center">
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                      <th>Waktu</th>
                      <th>Rata-rata Waktu</th>
                    </tr>
                  </thead>
                  <template v-if="(dataBPJS||[]).length">
                    <tbody>
                      <tr v-for="(v,k) in (dataBPJS||[])" :key="k">
                        <td>{{ v.tanggal| moment("DD MMMM YYYY")}}</td>
                        <td>{{v.namapoli}}</td>
                        <td>{{v.waktu_task1}}</td>
                        <td>{{v.avg_waktu_task1}}</td>

                        <td>{{v.waktu_task2}}</td>
                        <td>{{v.avg_waktu_task2}}</td>
                        
                        <td>{{v.waktu_task3}}</td>
                        <td>{{v.avg_waktu_task3}}</td>
                        
                        <td>{{v.waktu_task4}}</td>
                        <td>{{v.avg_waktu_task4}}</td>
                        
                        <td>{{v.waktu_task5}}</td>
                        <td>{{v.avg_waktu_task5}}</td>
                        
                        <td>{{v.waktu_task6}}</td>
                        <td>{{v.avg_waktu_task6}}</td>
                        
                        <td>{{v.jumlah_antrean}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <th colspan="2"><span class="font-weight-semibold">Total</span></th>
                        <td>{{totalBPJS.waktu_task1}}</td>
                        <td>{{totalBPJS.avg_waktu_task1}}</td>

                        <td>{{totalBPJS.waktu_task2}}</td>
                        <td>{{totalBPJS.avg_waktu_task2}}</td>

                        <td>{{totalBPJS.waktu_task3}}</td>
                        <td>{{totalBPJS.avg_waktu_task3}}</td>

                        <td>{{totalBPJS.waktu_task4}}</td>
                        <td>{{totalBPJS.avg_waktu_task4}}</td>

                        <td>{{totalBPJS.waktu_task5}}</td>
                        <td>{{totalBPJS.avg_waktu_task5}}</td>

                        <td>{{totalBPJS.waktu_task6}}</td>
                        <td>{{totalBPJS.avg_waktu_task6}}</td>
                  

                        <td>{{totalBPJS.jumlah_antrean}}</td>
                      </tr>
                    </tfoot>
                  </template>
                  <template v-else>
                    <template v-if="filter.typeFilter == 'DB'">
                      <td colspan="99" class="text-center"
                          v-if="!filter.bulan || !filter.tahun || !filter.type">
                          Data akan muncul setelah parameter disubmit
                      </td>
                      <td colspan="99" class="text-center"
                          v-else
                      >
                          Tidak ada Data
                      </td>                    
                    </template>
                    <template v-else>
                      <td colspan="99" class="text-center"
                          v-if="!filter.date || !filter.type">
                          Data akan muncul setelah parameter disubmit
                      </td>
                      <td colspan="99" class="text-center"
                          v-else
                      >
                          Tidak ada Data
                      </td>          
                    </template>

                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>    
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  data() {
    return {
      activeTab: 'RSH',
      dataRSH: {},
      dataBPJS: [],
      totalRSH: {},
      totalBPJS: {},
      mTahun: [],

      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      datePickerConfig: {
        startDate: new Date(),
        endDate: new Date(),
        autoApply: true,
        ranges: {
            'Hari Ini': [new Date(), new Date()],
            '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
            '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
            applyLabel: 'Terapkan',
            cancelLabel: 'Batal',
            direction: 'ltr',
            format: 'mm/dd/yyyy',
            separator: ' - ',
        }
      },
    }      
  },
  components:{
    DateRangePicker,Datepicker
  },
  methods: {
    doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doSync(dates){
      this.loadingOverlay=true
       Gen.apiRest(
          "/do/"+this.modulePage,
          {data:{type:'sync-data', dates: dates}}, 
          "POST"
      ).then(res=>{
        this.apiGet()
        this.loadingOverlay=false
      })
    },
    updateValues(e){
        this.doConvertDate()
        this.doFill()
    },
    doSearch(){
      this.apiGet()
    },  
    changeTab(tab){
      this.activeTab = tab
      this.filter = {}
      this.filter.activeTab = tab
      
      if(this.filter.activeTab == "BPJS"){
        this.filter.typeFilter = "DT"
        this.filter.type = "server"
        this.filter.date = moment().format('YYYY-MM-DD') 
      }

      this.doFill()
    },
    doFill(){
      if(this.filter.date){
        this.filter.date = moment(this.filter.date, "YYYY-MM-DD").format('YYYY-MM-DD')
      }
      this.doFilter()
    },
    changeTypeDashboard(e){
      this.filter = {}
      if(e == "DT"){
        this.filter.typeFilter = "DT"
        this.filter.type = "server"
        this.filter.date = moment().format('YYYY-MM-DD') 
        this.doFill()
      }else{
        this.filter.typeFilter = "DB"
        this.filter.type = "server"
        this.filter.bulan = moment().format('MM') 
        this.filter.tahun = moment().format('YYYY') 
        this.doFill()
      }
    },
    doFilter(urlOnly=false){
      if(this.exportType && this.filter){
          let f = Object.values(this.filter).filter(attr=>attr)
          if(f.length>=1){ 
          this.exportType = 'filtered'
          }else{
          this.exportType = 'all'
          }
      }
      let url = {
          name:this.$route.name,
          params: (this.$route.params||{}),
          query:Object.assign({}, {},_.clone(this.filter), {page:1})
      }
      
      if(urlOnly) return url
      this.pageNo=1
      this.$router.push(url).catch(()=>{})
    },


  },
  mounted() {
    this.apiGet()
  },
  filters: {
      date(val) {
          return val ? moment(val).format("D MMM YYYY") : ""
      }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>
